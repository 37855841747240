import {
  FC,
  useEffect,
  useState,
  ChangeEvent,
  InputHTMLAttributes,
} from 'react'
import {
  addInternationalCode,
  clearPhoneString,
  getInputMaskPhoneNumber,
  removeInternationalCode,
} from 'lib/phone'
import { IconWhatsApp } from 'icons'
import Input from '../Input'
import { TStatus } from '../Input/types'

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  handleOnChange: (phone: string) => void
  handleOnBlur?: () => void
  value?: string
  id: string
  status?: TStatus
}

const PhoneInput: FC<IProps> = ({
  handleOnChange,
  handleOnBlur,
  value = '',
  id,
  status,
  ...rest
}) => {
  const [phone, setPhone] = useState(() =>
    value ? removeInternationalCode(value) : '',
  )

  useEffect(() => {
    if (value !== undefined) setPhone(removeInternationalCode(value))
  }, [value])

  const formatInputValue = (e: ChangeEvent<HTMLInputElement>) => {
    const cleanPhone = clearPhoneString(e.currentTarget.value).slice(0, 11)
    setPhone(cleanPhone)
    handleOnChange(addInternationalCode(cleanPhone))
  }

  return (
    <Input
      status={status}
      leadingContent={{
        variant: 'icon',
        Icon: {
          Component: IconWhatsApp,
          title: 'Ícone: WhatsApp',
          color: 'grey900',
        },
      }}
      inputElementProps={{
        ...rest,
        id,
        value: getInputMaskPhoneNumber(phone),
        onBlur: handleOnBlur ?? handleOnBlur,
        onChange: formatInputValue,
        maxLength: 15,
      }}
    />
  )
}

export default PhoneInput
