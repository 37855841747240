import { FC } from 'react'
import classNames from 'classnames'
import Button from 'components/Button'
import { IconCross } from 'icons'
import styles from '../Modal.module.scss'

type TProps = {
  dark?: boolean
  floating?: boolean
  onClick?: () => void
}

const CloseButton: FC<TProps> = ({ dark, floating, onClick }) => {
  const className = classNames({
    [styles.ecModalCloseButtonDark]: dark,
    [styles.ecModalCloseButtonFloating]: floating,
  })
  return (
    <Button
      data-testid="ecModalCloseButton"
      aria-label="Fechar janela"
      theme="white"
      shape="circle"
      onClick={onClick}
      className={className}
      Icon={{
        Component: IconCross,
        color: 'grey900',
        title: 'Fechar',
      }}
    />
  )
}

export default CloseButton
