import { FC } from 'react'
import Typography from 'components/Typography'
import { TInputContent } from '../types'
import { sizeMap } from '../maps'
import styles from '../Input.module.scss'

const LeadingContent: FC<TInputContent> = ({
  size = 'medium',
  variant,
  Icon,
  value,
}) => {
  return (
    <div className={styles.ecInputLeadingContent}>
      {variant === 'icon' && Icon && (
        <Icon.Component
          color={Icon.color}
          title={Icon.title}
          width={sizeMap[size].iconSize}
          height={sizeMap[size].iconSize}
        />
      )}
      {variant === 'text' && value && (
        <Typography
          bold
          className={styles.ecInputClearButton}
          variant={sizeMap[size].contentTypographyVariant}
        >
          {value}
        </Typography>
      )}
    </div>
  )
}

export default LeadingContent
