import { FC, PropsWithChildren, ReactNode, useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'
import classNames from 'classnames'
import Button, { TProps as TPropsButton } from 'components/Button'
import useEventListener from 'hooks/useEventListener'
import Typography from 'components/Typography'
import CloseButton from './components/CloseButton'
import Background from './components/Background'
import styles from './Modal.module.scss'

export const backgroundAriaLabel = 'Fechar esta janela'

export type TProps = {
  visible?: boolean
  theme?: 'light' | 'dark'
  width?: 'small' | 'medium' | 'big' | 'bigger' | 'fullScreen'
  withHeight?: boolean
  fullHeight?: boolean
  bottom?: boolean
  hideHeader?: boolean
  hideCloseButton?: boolean
  header?: {
    leadingContent?: ReactNode
    title?: string
    trailingContent?: ReactNode
  }
  footer?: {
    align?: 'between' | 'evenly' | 'center' | 'stretch'
    children?: ReactNode
    buttons?: Array<{
      label: string
      props?: TPropsButton
    }>
  }
  disableContainerScroll?: boolean
  disableBackgroundClick?: boolean
  disableBackgroundScroll?: boolean
  disableCloseOnKey?: boolean
  handleCloseButtonClick: () => void
  handleBackgroundClick: () => void
  testId?: string
  isPortal?: boolean
  fullScreen?: boolean
}

const Modal: FC<PropsWithChildren<TProps>> = ({
  children,
  visible,
  theme = 'light',
  width,
  fullHeight,
  withHeight,
  bottom,
  hideHeader,
  hideCloseButton,
  header,
  footer,
  disableContainerScroll,
  disableBackgroundClick,
  disableBackgroundScroll,
  disableCloseOnKey,
  handleCloseButtonClick,
  handleBackgroundClick,
  testId,
  isPortal,
}) => {
  const refChildrenContainer = useRef<HTMLDivElement>(null)
  const isDark = theme === 'dark'

  const handleBackroundClick = () => {
    if (!disableBackgroundClick) {
      handleBackgroundClick()
    }
  }

  const handleEscape = (event: Event) => {
    if (
      (event as KeyboardEvent).key === 'Escape' &&
      !disableCloseOnKey &&
      visible
    ) {
      handleCloseButtonClick()
    }
  }

  useEventListener({
    type: 'keydown',
    listener: handleEscape,
  })

  useEffect(() => {
    if (disableBackgroundScroll) {
      document.body.style.overflow = visible ? 'hidden' : ''
    }
    return () => {
      if (disableBackgroundScroll) {
        document.body.style.overflow = ''
      }
    }
  }, [disableBackgroundScroll, visible])

  const className = classNames(styles.ecModal, {
    [styles.ecModalVisible]: visible,
    [styles.ecModalBottom]: bottom,
    [styles.ecModalDark]: isDark,
  })

  const containerClassName = classNames(styles.ecModalContainer, {
    [styles.ecModalContainerDark]: isDark,
    [styles.ecModalContainerBottom]: bottom,
    [styles.ecModalContainerWithHeight]: withHeight,
    [styles.ecModalContainerFullHeight]: fullHeight,
    [styles.ecModalContainerWidthSmall]: width === 'small',
    [styles.ecModalContainerWidthBig]: width === 'big',
    [styles.ecModalContainerWidthBigger]: width === 'bigger',
    [styles.ecModalContainerWidthFullScreen]: width === 'fullScreen',
  })

  const headerClassName = classNames(styles.ecModalHeader, {
    [styles.ecModalHeaderDark]: isDark,
  })

  const childrenContainerClassName = classNames(
    styles.ecModalChildrenContainer,
    {
      [styles.ecModalChildrenContainerNoScroll]: disableContainerScroll,
    },
  )

  const footerAlignMap = {
    center: {
      className: styles.ecModalFooterAlignCenter,
    },
    evenly: {
      className: styles.ecModalFooterAlignEvenly,
    },
    between: {
      className: styles.ecModalFooterAlignBetween,
    },
    stretch: {
      className: styles.ecModalFooterAlignStretch,
    },
  }

  const footerClassName = classNames(
    styles.ecModalFooter,
    footerAlignMap[footer?.align || 'between'].className,
    {
      [styles.ecModalFooterDark]: isDark,
    },
  )

  const ModalContent = (
    <div className={className} aria-hidden={!visible} data-testid={testId}>
      <div className={containerClassName}>
        {!hideHeader && (
          <div className={headerClassName}>
            <div className={styles.ecModalHeaderContent}>
              {header?.leadingContent}
            </div>
            {header?.title && (
              <Typography
                className={styles.ecModalHeaderTitle}
                variant="TitleSmall"
                bold
              >
                {header.title}
              </Typography>
            )}
            <div className={styles.ecModalHeaderContent}>
              {header?.trailingContent}
              {!hideCloseButton && (
                <CloseButton dark={isDark} onClick={handleCloseButtonClick} />
              )}
            </div>
          </div>
        )}
        {hideHeader && !hideCloseButton && (
          <CloseButton
            floating
            dark={isDark}
            onClick={handleCloseButtonClick}
          />
        )}
        <div ref={refChildrenContainer} className={childrenContainerClassName}>
          {children}
        </div>
        {footer && (
          <div className={footerClassName}>
            {footer.children && (
              <div className={styles.ecModalFooterChildren}>
                {footer.children}
              </div>
            )}
            {footer.buttons?.map(({ props = {}, label }) => (
              <Button
                key={label}
                {...props}
                className={classNames(
                  styles.ecModalFooterButton,
                  props.className,
                )}
              >
                {label}
              </Button>
            ))}
          </div>
        )}
      </div>
      <Background
        visible={visible}
        disabled={disableBackgroundClick}
        ariaLabel={backgroundAriaLabel}
        onClick={handleBackroundClick}
      />
    </div>
  )

  if (isPortal) {
    return createPortal(
      ModalContent,
      document.getElementById('modal-root-portal') as HTMLElement,
    )
  }

  return ModalContent
}

export default Modal
