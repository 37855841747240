import { FC } from 'react'
import classNames from 'classnames'
import styles from '../Modal.module.scss'

type TProps = {
  visible?: boolean
  disabled?: boolean
  ariaLabel: string
  onClick: () => void
}

const Background: FC<TProps> = ({
  visible = false,
  disabled = false,
  ariaLabel,
  onClick,
}) => {
  const className = classNames(styles.ecModalBackground, {
    [styles.ecModalBackgroundVisible]: visible,
  })

  return (
    <button
      type="button"
      disabled={disabled}
      className={className}
      aria-label={ariaLabel}
      onClick={onClick}
    />
  )
}

export default Background
