import { FC } from 'react'
import Typography from 'components/Typography'
import { IconCross, IconCircleCheck, IconCircleExclamationMark } from 'icons'
import { TInputContent, TStatus } from '../types'
import styles from '../Input.module.scss'
import { sizeMap } from '../maps'

export type TProps = TInputContent & {
  status?: TStatus
  allowClear?: boolean
  onClearButtonClick?: () => void
}
const TrailingContent: FC<TProps> = ({
  variant,
  Icon,
  value,
  size = 'medium',
  status,
  allowClear,
  onClearButtonClick,
}) => {
  return (
    <div className={styles.ecInputTrailingContent}>
      {allowClear && (
        <button
          type="button"
          aria-label="Limpar campo"
          className={styles.ecInputClearButton}
          onClick={onClearButtonClick}
        >
          <IconCross
            color="grey900"
            title="Ícone: limpar campo"
            width={sizeMap[size].iconSize}
            height={sizeMap[size].iconSize}
          />
        </button>
      )}
      {status === 'success' && (
        <IconCircleCheck
          color="green500"
          width={sizeMap[size].iconSize}
          height={sizeMap[size].iconSize}
          title="Ícone: dados válidos"
        />
      )}
      {status === 'error' && (
        <IconCircleExclamationMark
          color="red500"
          width={sizeMap[size].iconSize}
          height={sizeMap[size].iconSize}
          title="Ícone: dados inválidos"
        />
      )}
      {variant === 'icon' && Icon && (
        <Icon.Component
          color={Icon.color}
          title={Icon.title}
          width={sizeMap[size].iconSize}
          height={sizeMap[size].iconSize}
        />
      )}
      {variant === 'text' && value && (
        <Typography
          bold
          className={styles.ecInputClearButton}
          variant={sizeMap[size].contentTypographyVariant}
        >
          {value}
        </Typography>
      )}
    </div>
  )
}

export default TrailingContent
