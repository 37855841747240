import { FC, AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react'
import Link from 'next/link'
import Image from 'components/Image'
import { splitAnchorLinkProps, TAnchorElementProps } from 'components/Anchor'
import classNames from 'classnames'
import styles from './Tab.module.scss'
import { TTab } from './types'

type TButtonAttributes = ButtonHTMLAttributes<HTMLButtonElement>
type TAnchorAttributes = AnchorHTMLAttributes<HTMLAnchorElement>

type TConditionalProps =
  | ({ element?: 'a' } & TAnchorAttributes)
  | ({ element?: 'button' } & TButtonAttributes)

export type TProps = {
  colorScheme?: 'light' | 'dark'
} & TTab &
  TConditionalProps

const Tab: FC<TProps> = ({
  label,
  element = 'a',
  active,
  size,
  Icon,
  image,
  colorScheme = 'light',
  ...rest
}) => {
  const colorMap = {
    light: 'grey900',
    dark: 'white',
  }

  const getContent = () => (
    <>
      {Icon?.Component && (
        <span className={styles.ecTabIconWrapper}>
          <span className={styles.ecTabIcon}>
            <Icon.Component
              color={Icon.color || colorMap[colorScheme]}
              width={32}
              height={32}
              title={Icon.title}
            />
          </span>
        </span>
      )}
      {image && (
        <span className={styles.ecTabImageWrapper}>
          <Image
            fill
            className={styles.ecTabImage}
            hasLoaderSpinner={false}
            src={image.src}
            alt={image.alt}
            priority={image.priority}
          />
        </span>
      )}
      {label}
    </>
  )

  delete rest.activeKey
  const className = classNames(styles.ecTab, {
    [styles.ecTabActive]: active,
    [styles.ecTabSmall]: size === 'small',
  })

  if (element === 'a' && rest.href) {
    const { linkProps, anchorProps } = splitAnchorLinkProps({
      ...(rest as TAnchorElementProps),
      className,
    })

    return (
      <li className={styles.ecTabWrapper}>
        <Link {...linkProps} {...anchorProps}>
          {getContent()}
        </Link>
      </li>
    )
  }

  const buttonProps = {
    ...(rest as TButtonAttributes),
    className,
  }

  return (
    <li className={styles.ecTabWrapper}>
      <button type="button" {...buttonProps}>
        {getContent()}
      </button>
    </li>
  )
}

export default Tab
