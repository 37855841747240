import { FC, useRef, useEffect, CSSProperties } from 'react'
import classNames from 'classnames'
import { getVariableValue } from 'lib/styles'
import Button from 'components/Button'
import { IconChevronLeft, IconChevronRight } from 'icons'
import { TSize, TTab } from './types'
import Tab from './Tab'
import styles from './Tabs.module.scss'

type TTabsNavigation = {
  hide?: boolean
  onScrollToNext?: () => void
  onScrollToPrev?: () => void
}

export type TProps = {
  items: Array<TTab>
  activeKey?: string
  size?: TSize
  scrollAnimation?: boolean
  scrollOffset?: number
  navigation?: TTabsNavigation
  noPadding?: boolean
  className?: string
  colorScheme?: 'light' | 'dark'
}

export const isTabActive = (tab: TTab, activeKey: string | undefined) =>
  tab.exactKey
    ? activeKey === tab.activeKey
    : activeKey?.startsWith(tab.activeKey as string)

const Tabs: FC<TProps> = ({
  items,
  activeKey,
  size,
  scrollAnimation = false,
  scrollOffset = 0,
  navigation = { hide: true },
  noPadding,
  className,
  colorScheme = 'light',
}) => {
  const scrollRef = useRef<HTMLUListElement>(null)

  const colorMap = {
    dark: {
      primary: 'white',
      secondary: 'grey900',
      inactive: 'white',
      hover: 'grey300',
      tabBorderColor: 'grey900',
    },
    light: {
      primary: 'grey900',
      secondary: 'white',
      inactive: 'grey700',
      hover: 'grey500',
      tabBorderColor: 'grey900',
    },
  }

  const scrollToActiveTab = () => {
    if (!scrollRef.current) return

    const index = items.findIndex((item) => isTabActive(item, activeKey))
    if (index === -1) return

    const hasChildren = scrollRef.current.children.length > 0

    if (hasChildren) {
      const child: Partial<HTMLElement> = scrollRef.current.children[index]
      const childOffsetLeft = (child?.offsetLeft || 0) - scrollOffset
      const scrollLeft = index > 0 ? childOffsetLeft : 0

      scrollRef.current.scrollLeft = scrollLeft || 0
    }
  }

  /* istanbul ignore next */
  const scrollToNext = () => {
    const wrapperElement = scrollRef.current

    if (wrapperElement) {
      const wrapperWidth = wrapperElement?.getBoundingClientRect().width
      wrapperElement.scrollLeft += wrapperWidth

      if (navigation.onScrollToNext) navigation.onScrollToNext()
    }
  }

  /* istanbul ignore next */
  const scrollToPrev = () => {
    const wrapperElement = scrollRef.current

    if (wrapperElement) {
      const clientWidth = wrapperElement?.getBoundingClientRect().width
      wrapperElement.scrollLeft -= clientWidth

      if (navigation.onScrollToPrev) navigation.onScrollToPrev()
    }
  }

  useEffect(() => {
    if (scrollAnimation && items.length > 0 && activeKey) {
      scrollToActiveTab()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey])

  useEffect(() => {
    if (items.length > 0 && activeKey) {
      scrollToActiveTab()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (items.length < 1) return null

  return (
    <div
      data-testid="tabs"
      className={classNames(styles.ecTabs, className)}
      style={
        {
          '--primaryColor': getVariableValue(colorMap[colorScheme].primary),
          '--secondaryColor': getVariableValue(colorMap[colorScheme].secondary),
          '--inactiveColor': getVariableValue(colorMap[colorScheme].inactive),
          '--hoverColor': getVariableValue(colorMap[colorScheme].hover),
          '--tabBorderColor': getVariableValue(
            colorMap[colorScheme].tabBorderColor,
          ),
        } as CSSProperties
      }
    >
      <nav className={styles.ecTabsNav}>
        {!navigation?.hide && (
          <Button
            aria-label="Anterior"
            theme="ghost"
            shape="circle"
            size="small"
            onClick={scrollToPrev}
            Icon={{
              Component: IconChevronLeft,
              color: colorMap[colorScheme].primary,
              title: 'Anterior',
            }}
          />
        )}

        <ul
          className={classNames(styles.ecTabsList, {
            [styles.ecTabsListScrollAnimated]: scrollAnimation,
            [styles.ecTabsListNoPadding]: noPadding,
          })}
          ref={scrollRef}
          data-testid="tablist"
        >
          {items.map((item) => (
            <Tab
              colorScheme={colorScheme}
              key={item.activeKey}
              size={size}
              active={isTabActive(item, activeKey)}
              data-current={isTabActive(item, activeKey)}
              {...item}
            />
          ))}
        </ul>

        {!navigation?.hide && (
          <Button
            aria-label="Próximo"
            theme="ghost"
            shape="circle"
            size="small"
            onClick={scrollToNext}
            Icon={{
              Component: IconChevronRight,
              color: colorMap[colorScheme].primary,
              title: 'Próximo',
            }}
          />
        )}
      </nav>
    </div>
  )
}

export default Tabs
