import { FC, createElement, HTMLAttributes, PropsWithChildren } from 'react'
import classNames from 'classnames'
import Typography, {
  TVariant,
  TTypographyElements,
} from 'components/Typography'
import styles from './FormElement.module.scss'

type TSize = 'small' | 'medium' | 'big' | 'bigger' | 'biggest'
type TStatus = 'success' | 'error' | 'default'

export type TProps = {
  fieldsetContainer?: boolean
  title?:
    | {
        variant: 'label'
        value?: string
        htmlFor?: string
        required?: boolean
      }
    | {
        variant: TTypographyElements
        value?: string
        htmlFor?: never
        required?: boolean
      }
  helpText?: {
    ariaDescribeId?: string
    value?: string
  }
  size?: TSize
  status?: TStatus
}

type TSizeMap = Record<
  TSize,
  {
    titleVariant: TVariant
    helpTextVariant: TVariant
    titleBold?: boolean
  }
>

const Container: FC<
  { fieldsetContainer?: boolean } & HTMLAttributes<HTMLElement>
> = ({ fieldsetContainer, className, children }) => {
  const container = createElement(
    fieldsetContainer ? 'fieldset' : 'div',
    {
      className: classNames(styles.ecFormElement, className),
    },
    children,
  )
  return container
}

const FormElement: FC<PropsWithChildren<TProps>> = ({
  children,
  fieldsetContainer,
  title,
  helpText,
  size = 'medium',
  status = 'default',
}) => {
  const sizeMap: TSizeMap = {
    small: {
      titleVariant: 'ParagraphSmall',
      helpTextVariant: 'Label',
    },
    medium: {
      titleVariant: 'Paragraph',
      helpTextVariant: 'ParagraphSmall',
    },
    big: {
      titleVariant: 'TitleSmall',
      helpTextVariant: 'ParagraphSmall',
      titleBold: true,
    },
    bigger: {
      titleVariant: 'Title',
      helpTextVariant: 'ParagraphSmall',
      titleBold: true,
    },
    biggest: {
      titleVariant: 'TitleBig',
      helpTextVariant: 'ParagraphSmall',
      titleBold: true,
    },
  }

  const statusMap = {
    default: {
      helpTextClass: styles.ecFormElementHelpText,
    },
    success: {
      helpTextClass: styles.ecFormElementHelpTextSuccess,
    },
    error: {
      helpTextClass: styles.ecFormElementHelpTextError,
    },
  }

  return (
    <Container fieldsetContainer={fieldsetContainer}>
      {title?.value && (
        <div className={styles.ecFormElementTitleWrapper}>
          <Typography
            as={title.variant}
            htmlFor={(title.variant === 'label' && title?.htmlFor) || undefined}
            variant={sizeMap[size].titleVariant}
            bold={sizeMap[size].titleBold}
          >
            {title.value}{' '}
            {title.required && (
              <span className={styles.ecFormElementTitleRequired}>*</span>
            )}
          </Typography>
        </div>
      )}
      {children}
      {helpText?.value && (
        <Typography
          aria-describedby={helpText?.ariaDescribeId}
          className={status && statusMap[status].helpTextClass}
          variant={sizeMap[size].helpTextVariant}
        >
          {helpText.value}
        </Typography>
      )}
    </Container>
  )
}

export default FormElement
