import { TVariant } from 'components/Typography'
import styles from './Input.module.scss'

export const sizeMap = {
  small: {
    inputClassName: styles.ecInputSmall,
    inputFieldClassName: styles.ecInputFieldSmall,
    iconSize: 20,
    contentTypographyVariant: 'ParagraphSmall' as TVariant,
  },
  medium: {
    inputClassName: null,
    inputFieldClassName: null,
    iconSize: 24,
    contentTypographyVariant: 'Paragraph' as TVariant,
  },
  big: {
    inputClassName: styles.ecInputBig,
    inputFieldClassName: styles.ecInputFieldBig,
    iconSize: 28,
    contentTypographyVariant: 'ParagraphBig' as TVariant,
  },
}
