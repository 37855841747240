import { FC, ReactNode, useEffect, useId, useState } from 'react'
import Typography, { TProps as TTypographyProps } from 'components/Typography'
import { IconChevronDown } from 'icons'
import classNames from 'classnames'
import styles from './AccordionItem.module.scss'

export type TProps = {
  title: string
  content: ReactNode
  size?: 'small' | 'medium'
  customElements?: {
    title?: Pick<TTypographyProps, 'as'>
    disableToggleTitle?: boolean
  }
  isOpen?: boolean
  onExpand?: (title: string) => void
  onCollapse?: (title: string) => void
}

const defaultCustomElements = {
  title: {
    as: 'h3',
  },
  disableToggleTitle: false,
}

const AccordionItem: FC<TProps> = ({
  title,
  content,
  size = 'medium',
  isOpen = false,
  onExpand,
  onCollapse,
  customElements = defaultCustomElements,
}) => {
  const [open, setOpen] = useState(isOpen)
  const accordionId = useId()

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  const handleClick = () => {
    if (open && onCollapse) onCollapse(title)
    if (!open && onExpand) onExpand(title)
    setOpen(!open)
  }

  const sizeMap = {
    small: {
      title: {
        as: customElements.title?.as,
        variant: 'Paragraph',
      },
      iconSize: 20,
    },
    medium: {
      title: {
        as: customElements.title?.as,
        variant: 'TitleSmall',
      },
      iconSize: 24,
    },
  }

  const iconTitle = open ? 'Retrair:' : 'Expandir:'

  return (
    <div
      data-testid="ecAccordionItem"
      className={classNames(styles.ecItem, {
        [styles.ecItemExpanded]: open,
      })}
    >
      <Typography {...(sizeMap[size].title as TTypographyProps)} bold>
        <button
          type="button"
          onClick={handleClick}
          id={`ecAccordionTrigger${accordionId}`}
          aria-controls={`ecAccordionContent${accordionId}`}
          aria-expanded={open}
          className={styles.ecButton}
        >
          <span className={styles.ecIconContainer}>
            <IconChevronDown
              title={!customElements.disableToggleTitle ? iconTitle : ''}
              color="grey900"
              width={sizeMap[size].iconSize}
              height={sizeMap[size].iconSize}
            />
          </span>

          {title}
        </button>
      </Typography>

      <div
        data-testid="ecAccordionContent"
        aria-hidden={!open}
        id={`ecAccordionContent${accordionId}`}
        aria-labelledby={`ecAccordionTrigger${accordionId}`}
        className={styles.ecContent}
      >
        {content}
      </div>
    </div>
  )
}

export default AccordionItem
