import { FC, forwardRef, InputHTMLAttributes } from 'react'
import classNames from 'classnames'
import { TInputContent, TSize, TStatus } from './types'
import { sizeMap } from './maps'
import LeadingContent from './components/LeadingContent'
import TrailingContent from './components/TrailingContent'
import styles from './Input.module.scss'

type TInput = InputHTMLAttributes<HTMLInputElement>

export type TProps = {
  size?: TSize
  status?: TStatus
  allowClear?: boolean
  onClear?: () => void
  leadingContent?: TInputContent
  trailingContent?: TInputContent
  inputElementProps?: TInput
}

const Input: FC<TProps> = forwardRef<HTMLInputElement, TProps>(
  (
    {
      size = 'medium',
      status,
      allowClear,
      onClear,
      leadingContent,
      trailingContent,
      inputElementProps,
    },
    ref,
  ) => {
    const className = classNames(styles.ecInput, sizeMap[size].inputClassName, {
      [styles.ecInputDisabled]: inputElementProps?.disabled,
      [styles.ecInputSuccess]: status === 'success',
      [styles.ecInputError]: status === 'error',
    })

    const inputClassName = classNames(
      styles.ecInputField,
      sizeMap[size].inputFieldClassName,
      inputElementProps?.className,
    )

    return (
      <div className={className}>
        {leadingContent && <LeadingContent {...leadingContent} size={size} />}
        <input
          ref={ref}
          data-testid={inputElementProps?.id}
          {...inputElementProps}
          className={inputClassName}
        />
        {(trailingContent || status || allowClear) && (
          <TrailingContent
            {...trailingContent}
            size={size}
            status={status}
            allowClear={allowClear}
            onClearButtonClick={onClear}
          />
        )}
      </div>
    )
  },
)
Input.displayName = 'Input'

export default Input
