import { FC } from 'react'
import AccordionItem, {
  TProps as TAccordionItemProps,
} from './components/AccordionItem'
import styles from './Accordion.module.scss'

export type TProps = {
  items: TAccordionItemProps[]
  onExpand?: (title: string) => void
  onCollapse?: (title: string) => void
}

const Accordion: FC<TProps> = ({ items, onExpand, onCollapse }) => {
  return (
    <ul className={styles.ecList}>
      {items.map((item) => (
        <li key={item.title} className={styles.ecItem}>
          <AccordionItem
            {...item}
            onExpand={onExpand}
            onCollapse={onCollapse}
          />
        </li>
      ))}
    </ul>
  )
}

export default Accordion
